<template>
    <v-card outlined>
        <v-card-text>
            <v-row dense align="center" class="pa-2">
                <v-col cols="12" sm="6">
                    <v-autocomplete
                        v-validate="'required'"
                        dense
                        v-model="datos.idCliente"
                        :items="listaClientes"
                        item-text="rename"
                        placeholder="INNOUT SAC"
                        item-value="id"
                        :error-messages="errors.collect('datos.idCliente')"
                        label="Buscar Clientes"
                        data-vv-name="datos.idCliente"
                        data-vv-as="Tipo"
                        filled
                        rounded
                    >
                    </v-autocomplete>
                </v-col>
                <v-col>
                    <ul>
                        <li>Correo: {{ datos.emailClient }}</li>
                        <li>Telefono: {{ datos.phoneClient }}</li>
                    </ul>
                </v-col>
                <v-col cols="12" sm="6" class="py-2">
                    <v-btn dark small @click="openModalProveedor = true">
                        <b>Cliente</b>
                    </v-btn>
                    <v-btn dark small @click="dialog = true" v-if="user.idBussines === 1 && datos.phoneClient">
                        <b>Recordatorio</b>
                    </v-btn>
                </v-col>
                <v-dialog v-model="dialog" max-width="600">
                    <v-card>
                        <v-card-title>Ingresa la hora de la reserva:</v-card-title>
                        <v-card-text>
                            <v-text-field v-model="textHours" label="Ingrese el Horario aquí" outlined></v-text-field>
                            <h5>
                                Estimado cliente, le saludamos de Kunte Spa
                                <strong>{{ (sedes.find((x) => x.id == datos.idSede) || {}).province || 'Sede no encontrada' }}</strong> , se le recuerda que su
                                reserva se encontrará activa hoy a las <strong>{{ textHours }}</strong
                                >. <br />
                                Lo esperamos con la atención y calidez de siempre.
                            </h5>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn color="blue darken-1" text @click="dialog = false">Cancelar</v-btn>
                            <v-btn color="blue darken-1" text @click="openModalReminder">Enviar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-col cols="12" sm="4">
                    <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                dense
                                v-model="check_in"
                                :label="[2, 4].includes(user.idBussines) ? 'Fecha tentativa de Entrega' : 'Fecha Tentativa de Finalización'"
                                prepend-icon="mdi-calendar"
                                readonly
                                data-vv-name="check_in"
                                data-vv-as="fecha"
                                :error-messages="errors.collect('check_in')"
                                v-on="on"
                            />
                        </template>
                        <v-date-picker v-model="datos.check_in" no-title locale="es" @input="menu = false" />
                    </v-menu>
                </v-col>
                <v-col cols="12" sm="4">
                    <v-autocomplete
                        outlined
                        dense
                        v-model="datos.web_online"
                        :items="listPlataformWeb"
                        item-text="name"
                        placeholder="Plataforma"
                        item-value="name"
                        :error-messages="errors.collect('datos.web_online')"
                        label="Plataforma"
                        data-vv-name="datos.web_online"
                        data-vv-as="Plataforma"
                    />
                </v-col>
                <v-col cols="12" sm="12">
                    <v-textarea
                        dense
                        v-model="datos.observation"
                        v-validate="'required'"
                        :error-messages="errors.collect('datos.observation')"
                        label="Detalle de Reserva / Observaciones"
                        rows="2"
                        placeholder="Cliente pidió ..."
                        data-vv-name="datos.observation"
                        data-vv-as="observacion"
                    />
                </v-col>
                <v-row dense>
                    <v-col cols="12" sm="4">
                        <v-autocomplete
                            outlined
                            dense
                            v-model="datos.idTipoComprobante"
                            v-validate="'required'"
                            :items="tipoComprobante"
                            item-text="descripcion"
                            placeholder="Tipo Comprobante"
                            item-value="id"
                            :error-messages="errors.collect('datos.idTipoComprobante')"
                            label="Tipo Comprobante"
                            data-vv-name="datos.idTipoComprobante"
                            data-vv-as="Tipo"
                            required
                        />
                    </v-col>
                    <v-col cols="12" sm="4">
                        <v-text-field
                            dense
                            v-model="datos.numeroComprobante"
                            v-validate="'required'"
                            data-vv-name="datos.numeroComprobante"
                            data-vv-as="Numero Comprobante"
                            :error-messages="errors.collect('datos.numeroComprobante')"
                            label="N°  Comprobante"
                            placeholder="F001-0011"
                        />
                    </v-col>
                    <v-col cols="12" sm="4">
                        <v-text-field
                            dense
                            v-model="datos.numeroGuiaRemision"
                            v-validate="'required'"
                            data-vv-name="datos.numeroGuiaRemision"
                            data-vv-as="Numero Comprobante"
                            :error-messages="errors.collect('datos.numeroGuiaRemision')"
                            label="N° Guía Remisión"
                            placeholder="A01-0011"
                        />
                    </v-col>
                    <v-col cols="12" sm="4">
                        <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    dense
                                    v-model="datos.fechaFacturacion"
                                    label="Fecha de Facturación"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    data-vv-name="fechaFacturacion"
                                    data-vv-as="fechaFacturacion"
                                    :error-messages="errors.collect('datos.fechaFacturacion')"
                                    v-on="on"
                                />
                            </template>
                            <v-date-picker v-model="datos.fechaFacturacion" no-title locale="es" @input="menu2 = false" />
                        </v-menu>
                    </v-col>
                    <v-col cols="4">
                        <v-autocomplete
                            class="required"
                            dense
                            v-model="datos.idTipoMonedaFacturacion"
                            item-text="value"
                            item-value="id"
                            :items="tipoMoneda"
                            label="Moneda Facturacion"
                            v-validate="'required'"
                            :error-messages="errors.collect('datos.idTipoMonedaFacturacion')"
                            data-vv-name="datos.idTipoMonedaFacturacion'"
                            data-vv-as="Tipo Moneda"
                            required
                        />
                    </v-col>
                    <v-col cols="12" sm="4">
                        <v-text-field
                            dense
                            v-model="datos.montoFacturacion"
                            v-validate="'required'"
                            data-vv-name="datos.montoFacturacion"
                            data-vv-as="direccion"
                            :error-messages="errors.collect('datos.montoFacturacion')"
                            label="Monto de Facturaciòn"
                            @keypress="isNumber"
                        />
                    </v-col>
                    <v-col cols="12" sm="4">
                        <v-text-field
                            dense
                            v-model="datos.direccionFacturacion"
                            v-validate="'required'"
                            data-vv-name="datos.direccionFacturacion"
                            data-vv-as="direccion"
                            :error-messages="errors.collect('datos.direccionFacturacion')"
                            label="Direccion de Facturaciòn"
                            placeholder="AV la mar"
                        />
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col cols="12" sm="4">
                        <v-autocomplete
                            outlined
                            dense
                            v-model="datos.atributos.DocumentoRegistro.idTipoRegistro"
                            v-validate="'required'"
                            :items="tipoRegistroDocumento"
                            item-text="descripcion"
                            item-value="id"
                            :error-messages="errors.collect('datos.atributos.DocumentoRegistro.idTipoRegistro')"
                            data-vv-name="datos.atributos.DocumentoRegistro.idTipoRegistro"
                            placeholder="Tipo Documento"
                            data-vv-as="Tipo"
                            required
                        />
                    </v-col>
                    <v-col cols="12" sm="4">
                        <v-text-field
                            dense
                            v-model="datos.atributos.DocumentoRegistro.numeroComprobante"
                            v-validate="'required'"
                            data-vv-name="datos.atributos.DocumentoRegistro.numeroComprobante"
                            data-vv-as="Numero Comprobante"
                            :error-messages="errors.collect('datos.atributos.DocumentoRegistro.numeroComprobante')"
                            label="N°  Comprobante"
                            placeholder="F001-0011"
                        />
                    </v-col>
                    <v-col cols="12" sm="4">
                        <v-menu v-model="menu3" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    dense
                                    v-model="datos.atributos.DocumentoRegistro.fechaEmision"
                                    label="Fecha de Emisión"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    data-vv-name="fechaEmision"
                                    data-vv-as="fechaEmision"
                                    :error-messages="errors.collect('datos.atributos.DocumentoRegistro.fechaEmision')"
                                    v-on="on"
                                />
                            </template>
                            <v-date-picker v-model="datos.atributos.DocumentoRegistro.fechaEmision" no-title locale="es" @input="menu3 = false" />
                        </v-menu>
                    </v-col>
                    <v-col cols="4">
                        <v-autocomplete
                            class="required"
                            dense
                            v-model="datos.atributos.DocumentoRegistro.idTipoMonedaFacturacion"
                            item-text="value"
                            item-value="id"
                            :items="tipoMoneda"
                            label="Moneda Facturacion"
                            v-validate="'required'"
                            :error-messages="errors.collect('datos.atributos.DocumentoRegistro.idTipoMonedaFacturacion')"
                            data-vv-name="datos.atributos.DocumentoRegistro.idTipoMonedaFacturacion'"
                            data-vv-as="Tipo Moneda"
                            required
                        />
                    </v-col>
                    <v-col cols="12" sm="4">
                        <v-text-field
                            dense
                            v-model="datos.atributos.DocumentoRegistro.montoFacturacion"
                            v-validate="'required'"
                            data-vv-name="datos.atributos.DocumentoRegistro.montoFacturacion"
                            data-vv-as="direccion"
                            :error-messages="errors.collect('datos.atributos.DocumentoRegistro.montoFacturacion')"
                            label="Monto de Facturaciòn"
                            @keypress="isNumber"
                        />
                    </v-col>
                </v-row>
            </v-row>
        </v-card-text>
        <v-card-text class="lightenPrimary">
            <v-card outlined>
                <v-tabs v-model="subTab" background-color="secondaryTheme" dark slider-color="primary" grow show-arrows v-if="user.idBussines === 1">
                    <v-tab v-for="(item, key) in tipoServicio" :key="key" ripple>{{ item }}</v-tab>
                    <v-tab-item key="2">
                        <SelectPrivados></SelectPrivados>
                    </v-tab-item>
                    <v-tab-item key="3" class="white">
                        <SelectProductos></SelectProductos>
                    </v-tab-item>
                    <v-tab-item key="4" class="white">
                        <SelectOthers></SelectOthers>
                    </v-tab-item>
                </v-tabs>
                <v-tabs v-model="subTab" background-color="secondaryTheme" dark slider-color="primary" grow show-arrows v-else>
                    <v-tab v-for="(item, key) in tipoServicio" :key="key" ripple>{{ item }}</v-tab>
                    <v-tab-item key="0">
                        <SelectProductos></SelectProductos>
                    </v-tab-item>
                </v-tabs>
                <v-dialog max-width="100rem" dense v-model="openModalProveedor" persistent>
                    <CrearCliente @getIdClient="fnListener" @cleanCache="clientCache" />
                </v-dialog>
            </v-card>
        </v-card-text>
    </v-card>
</template>
<script>
import { format, differenceInDays, parseISO } from 'date-fns'
import { mapGetters, mapState } from 'vuex'
import CrearCliente from '../../components/clientes/CrearCliente'
import SelectPrivados from '@/components/selects/SelectPrivados'
import SelectProductos from '@/components/selects/SelectProductos'
import SelectOthers from '@/components/selects/SelectOthers'
import { mixins } from '@/mixins/mixin.js'

export default {
    components: {
        SelectPrivados,
        CrearCliente,
        SelectProductos,
        SelectOthers
    },
    filters: {},
    mixins: [mixins],
    $_veeValidate: {
        validator: 'new'
    },
    data() {
        return {
            subTab: 0,
            date: new Date(),
            dialog: false,
            textHours: '',
            openModalProveedor: false,
            menu: false,
            menu2: false,
            menu3: false,
            time: format(new Date(), 'HH:mm'),
            selectCliente: [
                { id: 1, text: 'NUEVO' },
                { id: 2, text: 'FRECUENTE' }
            ],
            listaProdReserva: []
        }
    },
    computed: {
        ...mapState('utilities', ['tipoDocumento', 'tipoMoneda', 'condicionPago', 'tipoComprobante', 'tipoRegistroDocumento']),
        ...mapState('proveedor', ['listPlataformWeb']),
        tipoServicio() {
            if ([2, 4].includes(this.user.idBussines)) {
                return ['PRODUCTOS']
            } else {
                return ['SERVICIOS', 'PRODUCTOS', 'OTROS']
                // return ['SAUNA MIXTO', 'MASAJES', 'PRIVADOS', 'PRODUCTOS', 'CORTESÍA']
            }
        },
        ...mapState('ventas', ['datos']),
        ...mapState('users', ['user']),
        ...mapState('sedes', ['sedes']),
        ...mapGetters('clientes', ['listaClientes']),
        check_in: {
            get() {
                //console.log('get')
                // if (this.datos.check_in.split('/')[1]) {
                //     //console.log('this.datos.check_in.spli', this.datos.check_in.split('/')[1])
                //     return `${this.datos.check_in.split('/')[2]}-${this.datos.check_in.split('/')[1]}-${this.datos.check_in.split('/')[0]}`
                // } else {
                //     //console.log('this.datos.check_in.spl', this.datos.check_in.split('/')[1])
                // }
                return this.datos.check_in
            },
            set(v) {
                //console.log('set')
                this.$store.commit('ventas/UPDATE_DATOS', {
                    check_in: format(parseISO(v), 'yyyy-MM-dd')
                })
            }
        },
        idCliente() {
            return this.datos.idCliente
        }
    },
    watch: {
        check_in(val) {
            //  //console.log('val ', val, differenceInDays(val, format(new Date(), 'yyyy-MM-dd')))
            const validaDate = differenceInDays(parseISO(val), format(new Date(), 'yyyy-MM-dd')) > 0
            // //console.log('validaDate ', validaDate)
            if (validaDate) {
                this.$store.commit('ventas/UPDATE_DATOS', {
                    tipoVenta: 2
                })
            } else {
                this.$store.commit('ventas/UPDATE_DATOS', {
                    tipoVenta: 1
                })
            }
        },
        idCliente(val) {
            console.log('--------idCliente----------- ', val)
            if (val) {
                const findCliente = this.listaClientes.find((x) => x.id === val)
                console.log('findCliente', findCliente)
                this.$store.commit('ventas/UPDATE_DATOS', {
                    numeroDocumento: findCliente.number,
                    emailClient: findCliente.email,
                    phoneClient: findCliente.telephone,
                    full_name: findCliente.defaultName,
                    idTipoDocumento: findCliente.identity_document_type_id
                })
            }
        }
    },
    async created() {
        this.$store.dispatch('clientes/getListaCli', { active: 0 })
        this.datos.atributos.DocumentoRegistro = { ...this.datos.atributos.DocumentoRegistro }
    },
    async mounted() {
        await this.$store.dispatch('proveedor/getAllPLataformWeb')
    },
    methods: {
        fnListener(v) {
            this.$store.commit('ventas/UPDATE_DATOS', {
                idCliente: v
            })
            console.log('datos_fnListener', this.datos)
            this.openModalProveedor = false
        },
        clientCache() {
            this.openModalProveedor = false
        },
        openModalReminder() {
            const { celularContacto, telefono, idSede, phoneClient } = this.datos
            console.log('this.datos', this.datos)
            console.log('this.sedes', this.sedes)
            const findSede = this.sedes.find((x) => x.id == idSede).province
            console.log('findSede', findSede)
            console.log('textHours', this.textHours)
            const msgWhatsapp = `Estimado cliente, le saludamos de Kunte Spa ${findSede},
            %0ASe le recuerda que su reserva se encontrará activa hoy a las ${this.textHours}.
            %0ALo esperamos con la atención y calidez de siempre.`
            console.log('msgWhatsapp', msgWhatsapp)
            console.log('url', `https://api.whatsapp.com/send?phone=51${celularContacto || telefono || phoneClient}&text=${msgWhatsapp}`)
            window.open(`https://api.whatsapp.com/send?phone=51${celularContacto || telefono || phoneClient}&text=${msgWhatsapp}`, '_blank')
        },
        async getDatosExternal() {
            //console.log('this.datos.numeroDocumento.length ', this.datos.numeroDocumento.length)
            if (this.datos.numeroDocumento.length >= 8) {
                try {
                    this.$store.commit('mainUI/OPEN_MODAL', {
                        state: true,
                        text: 'Buscando en Reniec/Sunat',
                        color: 'green'
                    })
                    const resultado = await this.$store.dispatch('clientes/getClienteExternal', {
                        idTipoDocumento: this.datos.idTipoDocumento,
                        numeroDocumento: this.datos.numeroDocumento
                    })
                    this.$store.commit('ventas/UPDATE_DATOS', {
                        idCliente: resultado.idCliente,
                        direccionFacturacion: resultado.direccion || '',
                        full_name: resultado.full_name || '',
                        idClienteKey: resultado.id || '',
                        telefono: resultado.telefono || '',
                        cliente: resultado || '',
                        departamento: resultado.departamento || '',
                        provincia: resultado.provincia || '',
                        distrito: resultado.distrito || '',
                        direccion: resultado.direccion || ''
                    })
                    this.$store.commit('mainUI/OPEN_MODAL', { state: false })
                } catch (error) {
                    this.$store.commit('mainUI/OPEN_MODAL', { state: false })

                    //console.log('error ', error)
                }
            }
        }
    }
}
</script>
